import gql from "graphql-tag";
import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { Alert, Button, Table } from "react-bootstrap";
import HeaderBadge from "../HeaderBadge/HeaderBadge";

class Activities extends Component<any> {
  public render() {
    return (
      <>
        <Query
          query={gql`
            query GetActivities($offset: Int, $limit: Int) {
              activities(offset: $offset, limit: $limit) {
                id
                title
                date
                exercises {
                  ... on Sport {
                    type
                  }
                  ... on Boulder {
                    type
                  }
                  ... on Gym {
                    type
                  }
                }
                owner {
                  fullName
                }
                createdAt
              }
            }
          `}
          variables={{
            offset: 0,
            limit: 10,
          }}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }
            if (data.activities && data.activities.length > 0) {
              return (
                <>
                  <HeaderBadge
                    title="Activities"
                    badgeCount={data.activities.length}
                  />
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>User</th>
                        <th>Exercises</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.activities.map(
                        ({ id, title, date, exercises, createdAt, owner }) => (
                          <tr key={id}>
                            <td>{title}</td>
                            <td>{moment(date).format("LLL")}</td>
                            <td>{owner.fullName}</td>
                            <td>{exercises.length}</td>
                            <td>{moment(createdAt).fromNow()}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                      fetchMore({
                        variables: {
                          offset: data.activities.length,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) {
                            return prev;
                          }
                          return {
                            ...prev,
                            activities: [
                              ...prev.activities,
                              ...fetchMoreResult.activities,
                            ],
                          };
                        },
                      })
                    }
                  >
                    Load more
                  </Button>
                </>
              );
            }

            return (
              <>
                <HeaderBadge title="Activities" />
                <Alert variant="info">No activities could be found</Alert>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default Activities;
