import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Logo from "./../components/Navigation/icon.png";
import "./Login.css";

const Login = (props: any) => {
  const onFacebookResponse = async (response: any) => {
    const result = await fetch(
      `${process.env.REACT_APP_FACEBOOK_LOGIN_CALLBACK_URL}?access_token=${
        response.accessToken
      }`
    );

    const xAuthToken = result.headers.get("x-auth-token") as string;
    sessionStorage.setItem("token", xAuthToken);
    props.history.push("/dashboard");
  };

  const onGoogleResponseSuccess = async (response: any) => {
    const result = await fetch(
      `${process.env.REACT_APP_GOOGLE_LOGIN_CALLBACK_URL}?id_token=${
        response.tokenId
      }`
    );

    const xAuthToken = result.headers.get("x-auth-token") as string;
    sessionStorage.setItem("token", xAuthToken);
    props.history.push("/dashboard");
  };

  return (
    <div id="login-wrapper">
      <Container className="login-container">
        <Row className="justify-content-md-center">
          <Col xs={12}>
            <Card
              className="login-card text-center"
              style={{ width: "20rem", margin: "auto" }}
            >
              <Card.Header as="h5" style={{ padding: "2rem" }}>
                <img
                  src={Logo}
                  width={48}
                  height={48}
                  style={{
                    borderRadius: "10px",
                    margin: "0 auto 1rem auto",
                    display: "block"
                  }}
                />
                Quopi Dashboard
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                    callback={onFacebookResponse}
                    onFailure={res => alert(JSON.stringify(res))}
                    render={renderProps => (
                      <Button
                        variant="secondary"
                        size="lg"
                        onClick={renderProps.onClick}
                      >
                        Login with Facebook
                      </Button>
                    )}
                  />
                </Card.Text>
                <Card.Text>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                    buttonText="Login with Google"
                    theme="dark"
                    onSuccess={onGoogleResponseSuccess}
                    onFailure={onGoogleResponseFailure =>
                      alert(JSON.stringify(onGoogleResponseFailure))
                    }
                    cookiePolicy={"single_host_origin"}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
