import gql from "graphql-tag";
import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { Alert, Button, Table } from "react-bootstrap";
import HeaderBadge from "../HeaderBadge/HeaderBadge";

interface IUsersState {
  userCount: number | null;
}

class Users extends Component<any, IUsersState> {
  constructor(props) {
    super(props);
    this.state = {
      userCount: null
    };
  }

  public render() {
    return (
      <>
        <Query
          query={gql`
            query GetUsers($offset: Int, $limit: Int) {
              users(offset: $offset, limit: $limit) {
                id
                fullName
                email
                followingUsersCount
                publicActivitiesCount
                privateActivitiesCount
                createdAt
                lastActiveAt
              }
            }
          `}
          variables={{
            offset: 0,
            limit: 10
          }}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }
            if (data.users && data.users.length > 0) {
              return (
                <>
                  <HeaderBadge title="Users" badgeCount={data.users.length} />
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Last active</th>
                        <th>Public activities</th>
                        <th>Private activities</th>
                        <th>Friends</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.users.map(
                        ({
                          id,
                          fullName,
                          email,
                          followingUsersCount,
                          publicActivitiesCount,
                          privateActivitiesCount,
                          createdAt,
                          lastActiveAt
                        }) => (
                          <tr key={id}>
                            <td>{fullName}</td>
                            <td>{email}</td>
                            <td>{moment(lastActiveAt).fromNow()}</td>
                            <td>{publicActivitiesCount}</td>
                            <td>{privateActivitiesCount}</td>
                            <td>{followingUsersCount}</td>
                            <td>{moment(createdAt).fromNow()}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                      fetchMore({
                        variables: {
                          offset: data.users.length
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) {
                            return prev;
                          }
                          return {
                            ...prev,
                            users: [...prev.users, ...fetchMoreResult.users]
                          };
                        }
                      })
                    }
                  >
                    Load more
                  </Button>
                </>
              );
            }

            return (
              <>
                <HeaderBadge title="Users" />
                <Alert variant="info">No users could be found</Alert>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default Users;
