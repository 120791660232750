import React from "react";
import { Badge } from "react-bootstrap";

interface IHeaderBadgeProps {
  title: string;
  badgeCount?: number;
}

const HeaderBadge = (props: IHeaderBadgeProps) => (
  <h3>
    {props.title}
    {props.badgeCount && (
      <>
        {" "}
        <Badge variant="secondary">{props.badgeCount}</Badge>
      </>
    )}
  </h3>
);

export default HeaderBadge;
