import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, from } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${sessionStorage.getItem("token") || null}`,
    },
  }));
  return forward ? forward(operation) : null;
});

const clientVersionMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "client-version": "Quopi/100.0.0(31) iOS/10.3",
    },
  }));
  return forward ? forward(operation) : null;
});

export const client = new ApolloClient({
  link: from([authMiddleware, clientVersionMiddleware, httpLink]),
  cache,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
