import React from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { LogOut } from "react-feather";
import { NavLink } from "react-router-dom";
import Icon from "./icon.png";
import "./Navigation.css";

const Navigation = (props: any) => (
  <Navbar bg="dark" variant="dark" style={{ marginBottom: "2rem" }}>
    <Navbar.Brand to="/dashboard">
      <img
        alt=""
        src={Icon}
        width="30"
        height="30"
        className="d-inline-block align-top"
        style={{ borderRadius: "5px", marginRight: "10px" }}
      />
      Quopi
    </Navbar.Brand>
    <Nav className="mr-auto">
      {/* <Nav.Item>
        <Nav.Link
          as={NavLink}
          to="/dashboard"
          href="/dashboard"
          active={location.pathname === "/dashboard"}
        >
          Dashboard
        </Nav.Link>
      </Nav.Item> */}
    </Nav>
    <Nav>
      <Nav.Item>
        <NavLink to="/logout">
          <Button variant="secondary" size="sm">
            <LogOut
              style={{ marginTop: "-1px", marginRight: "5px" }}
              size={18}
            />
            Logout
          </Button>
        </NavLink>
      </Nav.Item>
    </Nav>
  </Navbar>
);

export default Navigation;
