import React from "react";
import { client } from "..";
import "./Login.css";

class Logout extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.logout();
  }

  public logout = () => {
    sessionStorage.clear();
    client.clearStore();
    this.props.history.replace("/");
  };

  public render() {
    return null;
  }
}

export default Logout;
