import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Activities from "../components/Activities/Activities";
import Locations from "../components/Locations/Locations";
import Navigation from "../components/Navigation/Navigation";
import Users from "../components/Users/Users";

interface IDashboardState {
  userCount: number | null;
}

class Dashboard extends Component<any, IDashboardState> {
  constructor(props) {
    super(props);
    this.state = {
      userCount: null
    };
  }

  public updateUserCount = userCount => {
    if (this.state.userCount) {
      return;
    }
    this.setState({ userCount });
  };

  public render() {
    return (
      <>
        <Navigation />
        <Container>
          <Row>
            <Col>
              <h1>Dashboard</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              <Users />
            </Col>
          </Row>{" "}
          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              <Activities />
            </Col>
          </Row>{" "}
          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              <Locations />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
