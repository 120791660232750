import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";

const App = () => {
  const isLoggedIn = () => {
    const token = sessionStorage.getItem("token");
    return token && token.length > 0;
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to={isLoggedIn() ? "/dashboard" : "/login"} />
        <Route
          exact
          path="/login"
          render={routeProps => <Login {...routeProps} />}
        />
        <PrivateRoute exact path="/logout" component={Logout} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
