import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navigation from "../components/Navigation/Navigation";

class Dashboard extends Component {
  public render() {
    return (
      <>
        <Navigation />
        <Container>
          <Row>
            <Col>
              <h1>Page not found</h1>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
