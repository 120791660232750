import gql from "graphql-tag";
import moment from "moment";
import React, { Component } from "react";
import { Query } from "react-apollo";
import { Alert, Table } from "react-bootstrap";
import HeaderBadge from "../HeaderBadge/HeaderBadge";

interface ILocationsState {
  locationCount: number | null;
}

class Locations extends Component<any, ILocationsState> {
  constructor(props) {
    super(props);
    this.state = {
      locationCount: null
    };
  }

  public render() {
    return (
      <>
        <Query
          query={gql`
            {
              locations(offset: 0, limit: 100) {
                id
                name
                createdAt
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }
            if (data.locations && data.locations.length > 0) {
              return (
                <>
                  <HeaderBadge
                    title="Locations"
                    badgeCount={data.locations.length}
                  />
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.locations.map(({ id, name, createdAt }) => (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{moment(createdAt).fromNow()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              );
            }

            return (
              <>
                <HeaderBadge title="Locations" />
                <Alert variant="info">No locations could be found</Alert>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default Locations;
